import * as actions from "./types";

export const login = (credentials, callback) => ({
  type: actions.FETCH_LOGIN_REQUEST,
  credentials,
  callback
});

export const reauth = () => ({
  type: actions.FETCH_REAUTH
});

export const loginSuccess = user => ({
  type: actions.FETCH_LOGIN_SUCCESS,
  user
});

export const logout = () => ({
  type: actions.USER_LOGOUT
});

export const fetchContacts = () => ({
  type: actions.FETCH_CONTACTS_REQUEST
});

export const updateContact = (contact, setSubmittingCb) => ({
  type: actions.FETCH_CONTACT_UPDATE_REQUEST,
  contact,
  setSubmittingCb
});

export const setContactListExpanded = expanded => ({
  type: actions.SET_CONTACT_LIST_EXPANDED,
  expanded
});

export const setContactListViewStyle = style => ({
  type: actions.SET_CONTACT_LIST_VIEWSTYLE,
  style
});

export const setSearchQuery = query => ({
  type: actions.SET_SEARCH_QUERY,
  query
});
