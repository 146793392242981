import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { Link, withRouter } from "react-router-dom";

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  text: {
    display: "inline-block",
    verticalAlign: "text-bottom"
  }
});

const Back = props => {
  const { classes, to } = props;
  const { pathname, title } = to;
  return (
    <div>
      <Typography variant="h6" gutterBottom className={classes.root}>
        <Link className={classes.link} to={{ pathname: pathname || "/" }}>
          <ArrowBack />
          <span className={classes.text}>
            Back {title ? `to ${title}` : "Home"}
          </span>
        </Link>
      </Typography>
    </div>
  );
};

export default withRouter(withStyles(styles)(Back));
