import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import LoginForm from "./form";
const logo = require("../../assets/images/logo.png");

const LoginDialog = props => {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            <img
              style={{
                margin: "0 auto 2em auto",
                display: "flex",
                justifyContent: "center"
              }}
              width={100}
              height={100}
              src={logo}
              alt="logo"
            />
          </DialogContentText>
          <LoginForm
            onSubmitComplete={(failure) => !failure && handleClose()}
            redirectOnLogin={false}
            component={DialogActions}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default LoginDialog;
