import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/types";
import agent from "../agent";

function* loginUser(action) {
  try {
    const result = yield call(agent.Auth.login, action.credentials.username, action.credentials.password);
    const user = result.user;
    agent.setToken(user.token);
    yield put({type: actions.FETCH_CONTACTS_REQUEST});
    yield put({type: actions.FETCH_LOGIN_SUCCESS, user});
    action.callback && action.callback();
  } catch (e) {
    console.error(e);
    action.callback && action.callback(e.message);
    yield put({type: actions.FETCH_LOGIN_ERROR, error: e.message});
  }

}

function* reauthUser() {
  try {
    const result = yield call(agent.Auth.current);
    const user = result.user;
    yield put({type: actions.FETCH_CONTACTS_REQUEST});
    yield put({type: actions.FETCH_LOGIN_SUCCESS, user});
  } catch (e) {
    console.error(e);
    yield put({type: actions.FETCH_LOGIN_ERROR, error: e.message});
  }
}

function* fetchContacts(){
  try{
    const result = yield call(agent.Contacts.getAll);
    yield put({type: actions.FETCH_CONTACTS_SUCCESS, result});
  }catch(e){
    yield put({type:actions.FETCH_CONTACTS_ERROR, error: e.message});
  }
}

function* updateContact(action){
  try{
    console.dir("Contact update saga");
    console.dir(action);
    const result = yield call(agent.Contacts.edit, action.contact);
    const setSubmittingCb= action.setSubmittingCb;
    yield put({type: actions.FETCH_CONTACT_UPDATE_SUCCESS, result, setSubmittingCb});
    //yield put({type: actions.FETCH_CONTACTS_REQUEST}); // this seems to resolve the whole props.contacts.map not a function
  }catch(e){
    yield put({type: actions.FETCH_CONTACT_UPDATE_ERROR, error: e.message});
  }
}


function* loginSaga() {
  yield takeLatest(actions.FETCH_LOGIN_REQUEST, loginUser);
  yield takeLatest(actions.FETCH_REAUTH, reauthUser);
}

function* fetchContactsSaga(){
  yield takeLatest(actions.FETCH_CONTACTS_REQUEST, fetchContacts);
}

function* updateContactSaga(){
  yield takeLatest(actions.FETCH_CONTACT_UPDATE_REQUEST, updateContact);
}

export { loginSaga, fetchContactsSaga, updateContactSaga };