import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { LinearProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { login } from "../../actions";
import { createLoadingSelector } from "../../store/selectors";

const WrapButtons = ({
  container: Container,
  isSubmitting,
  handleSubmit,
  handleClose,
  ...rest
}) => {
  const buttons = (
    <React.Fragment>
      <Button
        onClick={handleClose}
        disabled={isSubmitting}
        tabIndex={2}
        variant="outlined"
      >
        Cancel
      </Button>
      <Button
        disabled={isSubmitting}
        color="primary"
        variant="contained"
        type="submit"
        tabIndex={3}
        style={{float: "right"}}
      >
        Login
      </Button>
    </React.Fragment>
  );
  if (Container != null) {
    return <Container {...rest}>{buttons}</Container>;
  } else {
    return buttons;
  }
};

const LoginDialog = ({
  buttonContainer,
  dispatch,
  onLogin,
  onSubmitComplete,
  history,
  redirectOnLogin,
  ...rest
}) => {
  let { from } = rest.location.state || { from: { pathname: "/" } };

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(null);
    dispatch(
      login({ ...values }, loginResponse => {
        if (loginResponse) {
          setStatus(loginResponse);
        } else {
          if (onLogin) {
            onLogin();
          } else {
            redirectOnLogin && history.push(from.pathname);
          }
        }
        setSubmitting(false);
        onSubmitComplete && onSubmitComplete(loginResponse);
      })
    );
  };
  return (
    <Formik
      initialValues={{username: "", password:""}}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .label("Email Address")
          .email()
          .required(),
        password: Yup.string()
          .label("Password")
          .required("Please enter your password")
      })}
      render={props => {
        const { isSubmitting, status } = props;
        return (
          <Form>
            <Field
              name="username"
              type="email"
              label="Email Address"
              autoFocus
              margin="normal"
              fullWidth
              component={TextField}
              tabIndex={0}
            />
            <Field
              name="password"
              margin="normal"
              label="Password"
              type="password"
              fullWidth
              component={TextField}
              tabIndex={1}
            />
            
            {isSubmitting && <LinearProgress variant="query" />}
            {!isSubmitting && <Typography variant="subtitle1" color="error" style={{ display: "block", marginTop: "1em" }}>{status}</Typography>}
            <div style={{ marginBottom: "1em" }} />
            <WrapButtons component={buttonContainer || null} {...rest} />
          </Form>
        );
      }}
    />
  );
};
const loadingSelector = createLoadingSelector(["FETCH_LOGIN_REQUEST"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  authenticated: Boolean(state.user.token)
});

export default withRouter(connect(mapStateToProps)(LoginDialog));
