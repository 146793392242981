import * as actions from "../../actions/types";
import agent from "../../agent";

const initialState = {
  username: null,
  email: null,
  token: null,
  image: null,
  authlog: Array(0).fill(null)
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        username: action.user.username,
        email: action.user.email,
        token: action.user.token,
        image: action.user.image,
        authlog: action.user.authlog
      };
    case actions.USER_LOGOUT:
      agent.setToken(null);
      return initialState;
    default:
      return state;
  }
};
