export const UnauthenticatedMenu = [
  {
    label: "Home",
    pathname: "/",
    exact: true
  },
  {
    label: "Login",
    pathname: "/login"
  }
];

export const AuthenticatedMenu = [
  {
    label: "Home",
    pathname: "/",
    exact: true
  },
  {
    label: "Dashboard",
    pathname: "/dashboard",
    exact: true
  },
  {
    label: "Contacts",
    pathname: "/contacts"
  }
];

const getMenu = authenticated =>
  authenticated ? AuthenticatedMenu : UnauthenticatedMenu;
export default getMenu;
