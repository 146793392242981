import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from "history";
import createRootReducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import * as sagas from "../sagas";
import localStorageMiddleware from "./localStorage";

export const history = createBrowserHistory();

const localStoragePersistence = JSON.parse(window.localStorage.getItem("localState")) || {};

const sagaMiddleware = createSagaMiddleware({
  onError: (e) => console.error(e, "Saga uncaught exception")
});

const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
  localStorageMiddleware
];

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
const store = createStore(
  createRootReducer(history), // root reducer with router state
  localStoragePersistence,
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(sagas.loginSaga);
sagaMiddleware.run(sagas.fetchContactsSaga);
sagaMiddleware.run(sagas.updateContactSaga);

export default store;
