import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TablePagination from "@material-ui/core/TablePagination";

const NoImage = require("../../assets/images/noimage.png");

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: 450
  },
  gridItem: {
    cursor: "pointer"
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  },
  paginationContainer: {
    width: "100%"
  }
});

const directionify = item => item.direction.toLowerCase() === "sent" ? "To" : "From";

const itemImageOrNull = item => (item.images && item.images[0]) || null;

function MailpieceGrid(props) {
  const { classes, mailpieces, contactName } = props;
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value * 1);
  };

  const handleChangePage = (event, page) => {
    setPage(page * 1);
  };

  return (
    <Paper className={classes.root}>
      <GridList cellHeight={180} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
          <ListSubheader component="div">list subhead</ListSubheader>
        </GridListTile>
        {mailpieces
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(item => (
            <GridListTile
              key={item._id}
              onClick={() => alert(item._id)}
              className={classes.gridItem}
            >
              <img
                src={(item.images[0] && item.images[0].uri) || NoImage}
                alt={
                  (itemImageOrNull(item) && itemImageOrNull(item).notes) || ""
                }
              />
              <GridListTileBar
                title={new Date(item.date).toLocaleDateString()}
                subtitle={
                  <span>
                    {directionify(item)} {contactName}
                  </span>
                }
                actionIcon={
                  <IconButton className={classes.icon}>
                    <VisibilityIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
      </GridList>
      <TablePagination
        classes={{root: classes.paginationContainer}}
        rowsPerPageOptions={[4, 6, 8, 10]}
        component="div"
        count={mailpieces.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        labelRowsPerPage={"Items per page"}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default withStyles(styles)(MailpieceGrid);
