import React from "react";
import version from "../../version";
import { Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Routes from "./routes";
import Nav from "../Nav";
import Spinner from "./spinner";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"Lato"', "sans-serif"].join(",")
  },
  palette: {
    secondary: {
      main: green[900]
    },
    primary: {
      main: red[700]
    }
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 200
    }
  }
});

const App = props => {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Nav />
        <Spinner />
        <Switch>
          <Routes />
        </Switch>
        <footer>&copy;&nbsp;Snailtrail.app&nbsp;2018 | {version}</footer>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
