import { combineReducers } from "redux";
import { connectRouter  } from "connected-react-router";
import loading from "./loading";
import user from "./user";
import contacts from "./contacts";
import contactList from "./contacts/contactList";
import search from "./search";

export default (history) => combineReducers({
  router: connectRouter(history) ,
  loading,
  user,
  contacts,
  contactList,
  search
});
