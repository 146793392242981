import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = "https://dev.snailtrail.app/api";

//const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set("authorization", `Token ${token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
};

const Auth = {
  current: () => requests.get("/user"),
  login: (email, password) =>
    requests.post("/users/login", { user: { email, password } }),
  register: (username, email, password) =>
    requests.post("/users", { user: { username, email, password } }),
  save: user => requests.put("/user", { user })
};
const Contacts = {
  getAll: () =>
    requests.get(
      '/v1/contact?populate=[{"path":"mailpieces"},{"path":"mailpieces.images"}]&sort=name'
    ),
  add: contact => requests.post("/v1/contact", contact),
  edit: contact => requests.put(`/v1/contact/${contact._id}/`, contact),
  delete: contact => requests.del(`/v1/contact/${contact._id}/`)
};
const Mailpieces = {
  add: mailpiece => requests.post("/v1/mailpieces/", mailpiece)
};

export default {
  Auth,
  Contacts,
  Mailpieces,
  setToken: _token => {
    token = _token;
  }
};
