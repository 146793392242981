import * as actions from "../actions/types";
import agent from "../agent";

const setLocalPersistence = value => {
  let currentState =
    JSON.parse(window.localStorage.getItem("localState")) || {};
  window.localStorage.setItem(
    "localState",
    JSON.stringify({ ...currentState, ...value })
  );
  console.log("Updating persisted local storage with", value);
};

const localStorageMiddleware = store => next => action => {
  switch (action.type) {
    case actions.FETCH_LOGIN_SUCCESS:
      if (!action.error) {
        window.localStorage.setItem("jwt", action.user.token);
        agent.setToken(action.user.token);
      }
      break;
    case actions.USER_LOGOUT:
      window.localStorage.setItem("jwt", "");
      agent.setToken(null);
      break;
    case actions.SET_CONTACT_LIST_VIEWSTYLE:
      setLocalPersistence({ contactList: { viewStyle: action.style } });
      break;
    default:
      break;
  }
  next(action);
};
export default localStorageMiddleware;
