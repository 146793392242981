import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GridIcon from "@material-ui/icons/GridOn";
import ListIcon from "@material-ui/icons/List";
import MailIcon from "@material-ui/icons/Mail";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setContactListExpanded, setContactListViewStyle } from "../../actions";
import MailpieceDisplay from "../MailpieceDisplay/";
import Fuse from "fuse.js";

const backgroundShape = require("../../assets/images/shape.svg");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "address", "notes"]
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "10em",
    backgroundPosition: "bottom right",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: "center"
  },
  block: {
    padding: theme.spacing.unit * 2
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  contactItem: {
    marginTop: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  buttonTextDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  buttonImage: {
    margin: theme.spacing.unit
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1
  }
});

const getContacts = (contacts, searchQuery) => {
  if (searchQuery) {
    const fuse = new Fuse(contacts, fuseOptions);
    return fuse.search(searchQuery);
  } else {
    return contacts;
    // return Object.keys(contacts).map(key => contacts[key]);
  }
};

const NL2BR = props =>
  props.text.split("\n").map((item, key) => (
    <React.Fragment key={key}>
      {item}
      <br />
    </React.Fragment>
  ));

const StyledExpansionPanelSummary = withStyles(theme => ({
  content: {
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column"
    }
  }
}))(ExpansionPanelSummary);

const ContactList = props => {
  if (!props.contacts) return null;
  const DisplayTypeIcon = () => {
    switch (props.viewStyle) {
      case "grid":
        return <ListIcon />;
      case "list":
        return <GridIcon />;
      default:
        return null;
    }
  };
  const DisplayType = () => {
    switch (props.viewStyle) {
      case "grid":
        return "list";
      case "list":
        return "grid";
      default:
        return null;
    }
  };
  const contacts = getContacts(props.contacts, props.searchQuery);
  const { classes, theme, expanded } = props;
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid
            spacing={24}
            alignItems="center"
            justify="center"
            container
            className={classes.grid}
          >
            <Grid container item xs={12}>
              {contacts.map((contact, i) => (
                <Grid
                  item
                  xs={12}
                  key={contact._id}
                  className={classes.contactItem}
                >
                  <ExpansionPanel
                    expanded={expanded === contact._id}
                    onChange={() => props.handleChange(contact._id)}
                  >
                    <StyledExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography className={classes.heading}>
                        {contact.name}
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {contact.formattedAddress.replace(/\n/g, ", ")}
                      </Typography>
                    </StyledExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        <MailpieceDisplay
                          mailpieces={contact.mailpieces}
                          contactName={contact.name}
                          layout={props.viewStyle}
                        />
                      }
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        component={Link}
                        to={{ pathname: `/contacts/edit/${contact._id}` }}
                      >
                        <EditIcon className={classes.buttonImage} />
                        <span className={classes.buttonTextDesktop}>
                          Edit Contact
                        </span>
                      </Button>
                      <Button variant="outlined" className={classes.button}>
                        <MailIcon className={classes.buttonImage} />
                        <span className={classes.buttonTextDesktop}>
                          New Mailpiece
                        </span>
                      </Button>
                      <div className={classes.grow} />
                      <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={() => props.setViewStyle(DisplayType())}
                      >
                        {DisplayTypeIcon()}
                      </Button>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Zoom
          in={true}
          timeout={transitionDuration.enter}
          style={{
            transitionDelay: transitionDuration.exit
          }}
          unmountOnExit
        >
          <Fab
            className={classes.fab}
            color="primary"
            aria-label="Add"
            onClick={() => alert("clicked FAB")}
          >
            <PersonAddIcon />
          </Fab>
        </Zoom>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  contacts: state.contacts,
  user: state.user,
  expanded: state.contactList.expanded,
  viewStyle: state.contactList.viewStyle,
  searchQuery: state.search.query
});

const mapDispatchToProps = dispatch => ({
  handleChange: id => {
    dispatch(setContactListExpanded(id));
  },
  setViewStyle: value => dispatch(setContactListViewStyle(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(ContactList)));
