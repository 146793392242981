import * as actions from "../../actions/types";

const initialState = Array(0).fill(null);

export default (state = initialState, action) => {
  switch (action.type) {
  case actions.USER_LOGOUT:
    return null;
  case actions.FETCH_CONTACTS_SUCCESS:
    return Object.keys(action.result).map((key)=>{return action.result[key];});
  case actions.FETCH_CONTACT_UPDATE_ERROR:
    action.setSubmittingCb(false);
    return state;
  case actions.FETCH_CONTACT_UPDATE_SUCCESS:
    action.setSubmittingCb(false);
    return {
      ...state,
      ...action.contact
    };
  case actions.FETCH_CONTACTS_ERROR:
    return action;
  default:
    return state;
  }
};
