import * as actions from "../../actions/types";

const initialState = { query: "" };

export default (state = initialState, action) => {
  switch (action.type) {
  case actions.SET_SEARCH_QUERY:
    return {
      ...state,
      query: action.query
    };
  default:
    return state;
  }
};
