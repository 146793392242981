export const FETCH_REAUTH = "FETCH_REAUTH";
export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_ERROR = "FETCH_LOGIN_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR";

export const FETCH_CONTACT_UPDATE_REQUEST = "FETCH_CONTACT_UPDATE_REQUEST";
export const FETCH_CONTACT_UPDATE_SUCCESS = "FETCH_CONTACT_UPDATE_SUCCESS";
export const FETCH_CONTACT_UPDATE_ERROR = "FETCH_CONTACT_UPDATE_ERROR";

export const FETCH_MAILPIECES_REQUEST = "FETCH_MAILPIECES_REQUEST";
export const FETCH_MAILPIECES_SUCCESS = "FETCH_MAILPIECES_SUCCESS";
export const FETCH_MAILPIECES_ERROR = "FETCH_MAILPIECES_ERROR";

export const SET_CONTACT_LIST_EXPANDED = "SET_CONTACT_LIST_EXPANDED";
export const SET_CONTACT_LIST_VIEWSTYLE = "SET_CONTACT_LIST_VIEWSTYLE";

export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";