import React from "react";
import { connect } from "react-redux";

const Spinner = props => (
  <div id="loader-container" className={props.loading ? ' loading' : ''}>
    <div id="loader">Loading...</div>
  </div>
)
const mapStateToProps = state => ({
  loading: (Object.values(state.loading).indexOf(true) > -1)
});

export default connect(
  mapStateToProps,
  null,
)(Spinner);