import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateroute";
import ScrollToTop from "../ScrollToTop";
import NotFound from "../NotFound";
import LoginPage from "../Login/page";

import Home from "../Home";
import ContactList from "../ContactList";
import EditContact from "../EditContact";

const routes = props => (
  <ScrollToTop>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute exact path="/contacts" component={ContactList} />
      <PrivateRoute path="/contacts/edit/:id" component={EditContact} />

      <Route component={NotFound} />
    </Switch>
  </ScrollToTop>
);

export default routes;
