import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router-dom";
import store, { history } from "./store";
import App from "./components/App";
import registerServiceWorker from "./registerServiceWorker";
import "./assets/css/index.css";
import "./assets/css/loader.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "react-image-gallery/styles/css/image-gallery.css";
import agent from "./agent";
import { reauth } from "./actions";
const token = window.localStorage.getItem("jwt");
const initialState = store.getState();
if (token && !initialState.user.token) {
  agent.setToken(token);
  store.dispatch(reauth());
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <App />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
