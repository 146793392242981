import React from "react";
import MailpieceGrid from "./grid";
import MailpieceList from "./list";

const MailpieceDisplay = ({ layout, ...rest }) => {
  let comp = null;
  switch (layout) {
  case "grid":
    comp = <MailpieceGrid {...rest} />;
    break;
  case "list":
    comp = <MailpieceList {...rest} />;
    break;
  default:
    return null;
  }
  return comp;
};

export default MailpieceDisplay;
