import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

//import LoginModal from "../Login";

import LoginDialog from "../Login/dialog";

import { logout, login, setSearchQuery } from "../../actions";
import getMenu from "./Menu";
import logo from "../../assets/images/logo.png";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      profileMenuAnchorEL: null,
      mobileMenuOpen: false,
      user: props.user,
      menuDefinition: getMenu(props.user.token),
      loginDialogShowing: false
    };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.user !== prevState.user) {
      return {
        user: newProps.user,
        menuDefinition: getMenu(Boolean(newProps.user.token))
      };
    }
    return null;
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = event => {
    this.setState({ mobileMenuOpen: true });
  };

  mobileMenuClose = event => {
    this.setState({ mobileMenuOpen: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  menuItemIsCurrentPage = menuItem => {
    if (menuItem.exact) return menuItem.pathname === this.props.currentPath;
    return this.props.currentPath.startsWith(menuItem.pathname);
  };

  current = () =>
    this.state.menuDefinition.findIndex(item =>
      this.menuItemIsCurrentPage(item)
    );

  handleProfileMenuOpen = event => {
    console.log("Opening profile menu");
    this.setState({ profileMenuAnchorEL: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ profileMenuAnchorEL: null, loginDialogShowing: false });
    this.mobileMenuClose();
  };

  handleLoginLogout = () => {
    if (this.state.user.token) {
      this.props.logout();
      this.handleMenuClose();
    } else {
      this.setState({ loginDialogShowing: true });
    }
  };

  setSearchQuery = event => {
    const query = event.target.value;
    this.props.setSearchQuery(query);
  };
  render() {
    const { profileMenuAnchorEL } = this.state;
    const { classes } = this.props;
    const isProfileMenuOpen = Boolean(profileMenuAnchorEL);
    const { loginDialogShowing } = this.state;

    const renderProfileMenu = (
      <Menu
        anchorEl={profileMenuAnchorEL}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isProfileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
        <MenuItem onClick={this.handleLoginLogout}>
          {this.state.user.token ? "Logout" : "Login"}
        </MenuItem>
      </Menu>
    );
    return (
      <AppBar
        position="absolute"
        color="default"
        className={classes.appBar}
      >
        <Toolbar>
          <Grid container spacing={24} alignItems="baseline">
            <Grid item xs={12} className={classes.flex}>
              <div className={classes.iconContainer}>
                <IconButton
                  onClick={this.mobileMenuOpen}
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="Menu"
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className={classes.inline}>
                <Typography variant="h5" color="inherit" noWrap>
                  <img width={20} src={logo} alt="logo" />
                  <span className={classes.tagline}>Snailtrail.app</span>
                </Typography>
              </div>
              <div className={classes.search}>
                <Input
                  startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  defaultValue={this.props.searchQuery}
                  onChange={this.setSearchQuery}
                  variant="outlined"
                />
              </div>
              <div className={classes.grow} />
              <React.Fragment>
                <div className={classes.tabContainer}>
                  <SwipeableDrawer
                    anchor="left"
                    open={this.state.mobileMenuOpen}
                    onOpen={this.mobileMenuOpen}
                    onClose={this.mobileMenuClose}
                  >
                    <List>
                      {this.state.menuDefinition.map((item, index) => (
                        <ListItem
                          component={Link}
                          to={{
                            pathname: item.pathname,
                            search: this.props.location.search
                          }}
                          button
                          key={item.pathname}
                          onClick={this.mobileMenuClose}
                          selected={this.menuItemIsCurrentPage(item)}
                        >
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ))}
                    </List>
                    {renderProfileMenu}
                  </SwipeableDrawer>
                  <Tabs
                    value={this.current() || this.state.value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                  >
                    {this.state.menuDefinition.map((item, index) => (
                      <Tab
                        key={item.pathname}
                        component={Link}
                        to={{
                          pathname: item.pathname,
                          search: this.props.location.search
                        }}
                        classes={{ root: classes.tabItem }}
                        label={item.label}
                      />
                    ))}
                  </Tabs>
                </div>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  {this.state.user.token && (
                    <IconButton color="inherit">
                      <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                      </Badge>
                    </IconButton>
                  )}
                  {this.state.user.token && (
                    <IconButton color="inherit">
                      <Badge badgeContent={17} color="secondary">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  )}
                </div>
              </React.Fragment>
              <IconButton
                aria-owns={
                  this.state.mobileMenuOpen ? "material-appbar" : undefined
                }
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                className="profileButton"
              >
                <AccountCircle />
              </IconButton>
              {renderProfileMenu}
            </Grid>
          </Grid>
        </Toolbar>
        <LoginDialog
          open={loginDialogShowing}
          handleClose={this.handleMenuClose}
        />
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  currentPath: state.router.location.pathname,
  user: state.user,
  searchQuery: state.search.query
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  setSearchQuery: query => dispatch(setSearchQuery(query))
});

const styles = theme => ({
  appBar: {
    position: "sticky",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey["100"]}`,
    backgroundColor: "white"
  },
  inline: {
    display: "inline"
  },
  flex: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center"
    }
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  iconButton: {
    float: "right"
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  profileButton: {
    marginRight: "3em"
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Navigation)));
