import * as actions from "../../actions/types";

const initialState = { expanded: null, viewStyle: "list" };

export default (state = initialState, action) => {
  switch (action.type) {
  case actions.SET_CONTACT_LIST_EXPANDED:
    return {
      ...state,
      expanded: (action.expanded === state.expanded ? null : action.expanded)
    };
  case actions.SET_CONTACT_LIST_VIEWSTYLE:
    return {
      ...state,
      viewStyle: action.style
    };
  default:
    return state;
  }
};
