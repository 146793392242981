import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";

import Back from "../common/Back";

const backgroundShape = require("../../assets/images/shape.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "10em",
    backgroundPosition: "bottom right",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: "center"
  },
  block: {
    padding: theme.spacing.unit * 2
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  contactItem: {
    marginTop: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

class ContactList extends React.PureComponent {
  constructor(props) {
    super(props);

    //this.handleChange = this.handleChange.bind(this);
    this.state = {
      expanded: null
    };
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { contacts } = this.props;
      if (!(contacts && contacts.length)) return null;
    const contact = contacts.find(c => c._id === this.props.match.params.id);

    const { classes, theme } = this.props;
    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Back to={{ pathname: "/contacts", title: "Contacts" }} />
          <Grid container justify="center">
            <Grid
              spacing={24}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <div>
                <Grid container item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.heading}>
                      {contact.name}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      {contact.formattedAddress}
                    </Typography>
                  </Paper>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Zoom
            in={true}
            timeout={transitionDuration.enter}
            style={{
              transitionDelay: transitionDuration.exit
            }}
            unmountOnExit
          >
            <Fab
              className={classes.fab}
              color="primary"
              aria-label="Save"
              onClick={() => alert("clicked FAB")}
            >
              <SaveIcon />
            </Fab>
          </Zoom>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.contacts
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles, { withTheme: true })(ContactList))
);
